import React, { Component } from 'react';
export default class Header extends Component {
  render() {
      let resumeData = this.props.resumeData;
    return (
      <React.Fragment>
      {/* About Section
================================================== */}
 <section id="about">
   <div className="row">
     <div className="three columns">
       <img className="profile-pic" src="images/T-e1525270637933.jpg" alt="" />
     </div>
     <div className="nine columns main-col">
       <h2>About Me</h2>
       <p>{resumeData.aboutMe}
       </p>
       <div className="row">
         <div className="columns contact-details">
           <h2>Contact Details</h2>
           <p className="address">
             <span>{resumeData.name}</span><br />
             <span>{resumeData.address}<br />

             </span><br />
             <span>{resumeData.phone}</span><br />
             <span>{resumeData.email}</span>
           </p>
         </div>
         <div className="columns download">
         <p>
           <a href={resumeData.CV} target="_blank" className="button" style={{width: "100%"}}><i className="fa fa-download" />Full Resume (in French)</a>
         </p>
         <p>
           <a href={resumeData.CVOnePage} target="_blank" className="button" style={{width: "100%"}}><i className="fa fa-download" />One page Resume (in French)</a>
         </p>
         </div>
       </div> {/* end row */}
     </div> {/* end .main-col */}
   </div>
 </section> {/* About Section End*/}


      </React.Fragment>
    );
  }
}
