import React, { Component } from 'react';
import ResumeItem from './resumeItem';
import Skill from './skill';

export default class Header extends Component {
  render() {
      let resumeData = this.props.resumeData;
    return (
      <React.Fragment>
      {/* Resume Section
   ================================================== */}
      <section id="resume">
        {/* Education
      ----------------------------------------------- */}
        <div className="row education">
          <div className="three columns header-col">
            <h1><span>Education</span></h1>
          </div>
          <div className="nine columns main-col">

            {resumeData.education.map(item => (
                 <ResumeItem data={item}/>
               ))}
          </div> {/* main-col end */}
        </div> {/* End Education */}
        {/* Work
      ----------------------------------------------- */}
        <div className="row work">
          <div className="three columns header-col">
            <h1><span>Latest missions</span></h1>
          </div>
          <div className="nine columns main-col">
            {resumeData.work.map(item => (
                 <ResumeItem data={item}/>
               ))}

          </div> {/* main-col end */}
        </div> {/* End Work */}
        {/* Skills
      ----------------------------------------------- */}
        <div className="row skill">
          <div className="three columns header-col">
            <h1><span>Skills</span></h1>
          </div>
          <div className="nine columns main-col">
            <p>{resumeData.skillDescription}
            </p>
            <div className="bars">
              <ul className="skills">
                {resumeData.skills.map(skill => (
                     <Skill data={skill}/>
                   ))}

              </ul>
            </div>{/* end skill-bars */}
          </div> {/* main-col end */}
        </div> {/* End skills */}
      </section> {/* Resume Section End*/}


      </React.Fragment>
    );
  }
}
