import React, { Component } from 'react';
export default class Header extends Component {
  render() {
      let item = this.props.data;
    return (
      <React.Fragment>
      {/* Portfolio Section
   ================================================== */}
              <div className="columns portfolio-item">
                <div className="item-wrap">
                  <a href={"#"+item.id} title>
                    <img alt="" src={item.image} />
                    <div className="overlay">
                      <div className="portfolio-item-meta">
                        <h5>{item.title}</h5>
                        <p>{item.category}</p>
                      </div>
                    </div>
                    <div className="link-icon"><i className="icon-plus" /></div>
                  </a>
                </div>
              </div> {/* item end */}
          {/* Modal Popup
	      --------------------------------------------------------------- */}
          <div id={item.id} className="popup-modal mfp-hide">
            <img className="scale-with-grid" src={item.imageModal} alt="" />
            <div className="description-box">
              <h4>{item.title}</h4>
              <p>{item.description}</p>
              <span className="categories"><i className="fa fa-tag" />{item.categories}</span>
            </div>
            <div className="link-box">
              {item.link.length > 0 && <a href={item.link} target="_blank" rel="noopener noreferrer">Details</a>}
              <a className="popup-modal-dismiss">Close</a>
            </div>
          </div>{/* modal-01 End */}

      </React.Fragment>
    );
  }
}
