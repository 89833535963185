import React, { Component } from 'react';
export default class Header extends Component {
  render() {
    let item = this.props.data;
    return (
      <React.Fragment>

            <div className="row item">
              <div className="twelve columns">
                <h3>{item.title}</h3>
                <p className="info">{item.subtitle}<span>•</span> <em className="date">{item.date}</em></p>
                <p>{item.content}
                </p>
              </div>
            </div>

      </React.Fragment>
    );
  }
}
