import React, { Component } from 'react';
export default class Header extends Component {
  render() {
      let resumeData = this.props.resumeData;
    return (
      <React.Fragment>
      {/* Contact Section
     ================================================== */}
        <section id="contact">
          <div className="row section-head">
            <div className="two columns header-col">
              <h1><span>Get In Touch.</span></h1>
            </div>
            <div className="ten columns">
              <div className="widget widget_contact">
                <h4>Address and Phone</h4>
                <p className="address">
                  {resumeData.name}<br />
                  {resumeData.address}<br /><br />

                  {resumeData.phone}<br />
                  <span>{resumeData.email}</span>

                  
                </p>
              </div>
            </div>
          </div>
        </section> {/* Contact Section End*/}


      </React.Fragment>
    );
  }
}
