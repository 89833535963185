import React, { Component } from 'react';
import PortfolioItem from './portfolioItem';


export default class Header extends Component {
  render() {
    let resumeData = this.props.resumeData;

    return (
      <React.Fragment>
      {/* Portfolio Section
   ================================================== */}
      <section id="portfolio">
        <div className="row">
          <div className="twelve columns collapsed">
            <h1>Check Out Some of My Works.</h1>
            {/* portfolio-wrapper */}
            <div id="portfolio-wrapper" className="bgrid-quarters s-bgrid-thirds cf">
              {resumeData.portfolio.map(item => (
                   <PortfolioItem data={item}/>
                 ))}
            </div> {/* portfolio-wrapper end */}
          </div> {/* twelve columns end */}
        </div> {/* row End */}
      </section> {/* Portfolio Section End*/}


      </React.Fragment>
    );
  }
}
