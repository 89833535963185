import React, { Component } from 'react';
export default class Header extends Component {
  render() {
    let item = this.props.data;
    const divStyle = {
      width: item.level,
      //"-moz-animation": "css 2s ease",
    	//"-webkit-animation": "css 2s ease"

    };
    return (
      <React.Fragment>
          <li><span className="bar-expand" style={divStyle} /><em>{item.name}</em></li>
      </React.Fragment>
    );
  }
}
