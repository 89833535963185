let resumeData = {
  "name": "Thierry Carricaburu",
  "address":"94230 CACHAN, France",
  "phone":"+33 674737218",
  "email":"thierry.carricaburu@wanadoo.fr",
  "role": "DevOps Engineer",
  "CV":"CARRICABURU Thierry CV Complet.pdf",
  "CVOnePage":"CARRICABURU Thierry CV OnePage.pdf",
  "roleDescription": " helping companies of all sizes around the globe to improve their software development deliveries.",
    "aboutMe":"DevOps Engineer, I thrive on challenges, solving puzzles and reduce time on low value tasks for myself and my peers. My favorite book is the Phoenix Project. My best challenges are providing a solution for people, mostly using my technical skills but also by my think-out-of-the-box capabilities and my quite large experience. Most of the time people search for a specific skill but forget that they also need a roundabout to glue everything together and fill the gaps. My various experiences allow me to fill these gaps for you or even manage a whole human size project on my own from start to finish.",
    "education":[
      {title:"INSA",subtitle:"Computing Sciences",date:"1995",content:"Hardware and software engineering skills covering algorithmics, Operating system core building, logistics, project management"},
      {title:"Android",subtitle:"professionnal training",date:"2013",content:"Complete course on building an android application"},
    ],
    "work":[
      {title:"ITS Services",subtitle:"DevOps Engineer",date:" January 2022 - Present",content:"As a Devops Engineer I participate in various project to help the DevOps culture growth. I am used to the mainstream tools of the trade with a preference for AWS usage."},
      {title:"Virtual Regatta",subtitle:"Fullstack & DevOps Engineer",date:" Avril 2020 - November 2021",content:"Participate in the development of Virtual Regatta Offshore (The 1 million connected player game sponsored by the International Olympic Committee) and Virtual Regatta Inshore. We use Unity, Gamesparks, Photon and AWS environments."},
      {title:"Labsterium",subtitle:"Senior Developer",date:" March 2018 - Avril 2020",content:"Escape room digital game building on Unity. Dynamic control system based on a microservices approach. Arduino developments."},
      {title:"Oh my keys",subtitle:"Full stack developer",date:"April 2015 - March 2018",content:"Complete logistic solution including a front end website for customer with paying options, an operator website, a backoffice and some API to pilot delevry services companies and automated lockers"},
      {title:"Viapost",subtitle:"Full stack developer",date:"August 2010 - April 2015",content:"Internal employe. Worked on various logistic applications. This included ponctual chirstmas deliveries, Press delivery courier planning, Election documentations delivery."},
      {title:"Dexia",subtitle:"Full stack developer",date:"January 2008 - August 2010",content:"Consultant on a datamart application. Manage data integration and aggregation, Data correction, exportation for a Business Object Reporting dasboard for board of directors."},
      {title:"Affinion International",subtitle:"Business Architect",date:"July 2004 - January 2008",content:"Business architect in the european team. Managed country related projects in France, UK, Germany. Including cryptography applications, Windows 2000 migration, Marketing applications."},
    ],
    skillDescription:"As a DevOps Engineer, I work on some of the critical aspects of application deliveries. My background as software developer and IT in various industries is a huge help to communicate of both fronts and leverage them for the better of the DevOps culture.",
    skills:[
      {name:"Docker", level:"60%"},
      {name:"Jenkins", level:"50%"},
      {name:"CI/CD practice", level:"70%"},
      {name:"Linux", level:"70%"},
      {name:"AWS", level:"40%"},
      {name:"Development Language (C#, Javascript, Java, ...)", level:"80%"},
      {name:"Database (No SQL and SQL)", level:"60%"},
      {name:"DevOps tools and culture", level:"50%"},
    ],
    portfolio:[

      {
        id:"portfolio-01",
        image:"images/portfolio/Automated_WebGL.png",
        imageModal:"images/portfolio/Automated_WebGL.png",
        title:"CI/CD for Unity",
        category:"DevOps",
        description:"CI/CD chain to build and deploy in our Dev and production environments for webgl version of the game. PM2, nodeJS, APIs.",
        categories:"DevOps",
        link:"",
      },
      {
        id:"portfolio-02",
        image:"images/portfolio/omk800x800.png",
        imageModal:"images/portfolio/modals/omk1050x700.png",
        title:"Logistic applications",
        category:"web development",
        description:"Logistic application for a B2C business. Several web sites, APIs, Stripe integration.",
        categories:"web development, integration",
        link:"",
      },
      {
        id:"portfolio-03",
        image:"images/portfolio/Labsterium800x800.png",
        imageModal:"images/portfolio/modals/Labsterium1050x700.png",
        title:"Escape room solution",
        category:"web development",
        description:"Micro services applications to manage an escape room. Web site and services build around a MQTT Broker.",
        categories:"Web development, architecture, network development, microservices",
        link:"http://www.labsterium.com",
      },
      {
        id:"portfolio-04",
        image:"images/portfolio/StartWay800x800.png",
        imageModal:"images/portfolio/modals/Startway1050x700.png",
        title:"Booking application",
        category:"web development",
        description:"Web application to book entries inside a coworking space with security badges.",
        categories:"Web development, iOT",
        link:"",
      }
    ],
    social:[
      {link:"https://github.com/Nekogeekaku/",className:"fa fa-github"},
      {link:"https://www.linkedin.com/in/thierry-carricaburu-18450211",className:"fa fa-linkedin"},
    ]



  }
export default resumeData
